const state = () => {
  return {
    /** User IDs */
    id: [],
    /** Customer IDs */
    customers: [],
    /** Indicate to include expired items */
    expired: false,
    /** Date from */
    datestart: '',
    /** Date to */
    dateend: '',
    /**  Status of each item */
    status: '',
    /** Sort order */
    sort: 'desc',
    /** Page number */
    page: 1,
    /** Items per page */
    count: 10,
    /** If true, search in turbo mode */
    turbo: false
  }
}

// getters
const getters = {
  state: state => state
}

// actions
const actions = {
}

// mutations
const mutations = {
  /** Reset state */
  reset (status) {
    Object.assign(status, state())
  },

  /** Set state */
  state (state, val) {
    Object.assign(state, val)
  },
}

export const create = () => ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
})

export default create()