import _mergeWith from 'lodash/mergeWith'
import _cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import API from '@/api/index.js'
import {create as createFilter}from '@/store/modules/OctItemFilter.js'

const normalizeItem = item => {
  // Normalize data property
  item.data = Object.assign({
    recommendedItem: {
      item: '',
      message: ''
    }
  }, item.data)

  return Object.assign({
    /** Treatment ID */
    id: '',
    /** Customer ID */
    customer: '',
    /** Salon ID */
    salon: '',
    /** Videos */
    videos: [],
    /** Images */
    images: [],
    /** Stylists */
    stylists: [],
    /** Assistants */
    assistants: [],
    /** Shampoo item ID */
    shampoo: '',
    /** Treatment item ID */
    treatment: '',
    /** Out bath item ID */
    outbath: '',
    /** Other items (Array of ID) */
    others: [],
    /** Message */
    message: '',
    /** Status */
    status: 2,
    /** Date */
    data: {
      /** Recommended item */
      recommendedItem: {
        /** ID of recommended item */
        item: '',
        /** Message for recommended item */
        message: '',
      },
      /** Indicate the item is sent over 72 hours */
      overdue: true
    }
  }, item, {
    publishedAt: !item.publishedAt ? new Date() :
            (item.publishedAt.getFullYear ? item.publishedAt : new Date(item.publishedAt)),
    updatedAt: !item.updatedAt ? new Date() :
            (item.updatedAt.getFullYear ? item.updatedAt : new Date(item.updatedAt)),
  })
}

const state = () => {
  return {
    /** All items */
    all: {},
    /**
     * Collections
     * @property {}.items Item IDs
     * @property {}.total Total number of the collection
     */
    collections: {},
    /** Delay ID */
    timeoutId: {}
  }
}

// getters
const getters = {
  /*
  filter: (state) => (filter) => {
    state.all.filter(item => filter(item))
  },
  */
  /** @returns all items */
  //all: state => state.all,
  all: () => [],
  /** @returns Collection specified by ID */
  collection: state => id => (
    state.collections[id] ? {
      items: state.collections[id].items.reduce((acc, cur) => (
              state.all[cur] && acc.concat(state.all[cur]) || acc
            ), []),
      total: state.collections[id].total
    } : {items: [], total: 0}),
  /** Get items specified by ID */
  byId: state => id => state.all[id],
  /** @retuns child module */
  module: state => id => state[id],
  /** @returns Unsent items */
  //unsent: state => state.all.filter(item => item.status < 4),
  unsent: () => [],
  /** @returns Sent items */
  //sent: state => state.all.filter(item => item.status === 4),
  sent: () => [],
}

// actions
const actions = {
  fetch ({commit, dispatch}, {query, collection, turbo}) {
    // Variables for turbo mode
    const times = 5
    const count = query.count/times
    const page = (query.page-1)*times + 1
    const queryCloned = _cloneDeep(query)
    queryCloned.count = count

    // Fetch items and add to the collection
    return (turbo ? 
      // Fetch items asynchronously
      Promise.all([...Array(times).keys()].map(i => {
        queryCloned.page = page + i
        return API.getItems(queryCloned)
      }))
        .then(responses => ({
            items: responses.reduce((acc, cur) => acc.concat(cur.items), []),
            total: responses[0].total
          })
        ) :
      // Fetch items normally
      API.getItems(query)
    )
      .then(response => {
        // Refresh collection if fetching the first page
        (!query.page || query.page == 1) &&
          commit('resetCollection', {
            collection: collection,
            items: []
          })

        commit('add', {
          items: response.items,
          collection: collection,
          total: response.total
        })

        // Load customer data asynchronously 50 items at a time
        const len = 50
        for (let i = 0; i*50 < response.items.length; i++)
          dispatch('customers/add', {
            items: response.items.slice(i*len, (i+1)*len).map(item => ({id:item.customer}))
          }, {root:true})

        return response
      })
  },

  /** Fetch all items */
  async fetchAll ({dispatch}, {query, collection}) {
    // The results
    const items = []

    // Normalize payload
    query = Object.assign({}, {page: 1}, query)

    // Fetch all items
    while (
      await dispatch('fetch', {query, collection}).then(response => {
        items.push(...response.items)
        return response.items.length && items.length < response.total
      })
    ) query.page++

    return items
  },

  /** Search */
  async search ({state, commit, dispatch}) {
    // Search query
    const query = {
      id: state.filter.id.join(','),
      status: state.filter.status,
      datestart: state.filter.datestart,
      dateend: state.filter.dateend,
      sort: state.filter.sort,
      count: state.filter.count,
      page: state.filter.status === 3 ? 1 : state.filter.page
    }

    // If the query parameter is 3, load all items status 2 and 3 othewise fetch normally
    return state.filter.status === 3 ?
      Promise.all([
        dispatch('fetchAll', {query: Object.assign({}, query, {status:2})}),
        dispatch('fetchAll', {query: Object.assign({}, query, {status:3})})
      ]).then(responses => {
        const response = [...responses[0], ...responses[1]]
                          // Sort items
                          .sort((a, b) => query.sort === 'asc' ?
                            new Date(a.publishedAt).getTime() - new Date(b.publishedAt).getTime() :
                            new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
                          )
        commit('resetCollection', {collection:'searched', items:[]})
        commit('add', {collection:'searched', items:response, total:response.length})
      }) :
      dispatch('fetch', {query: query, collection: 'searched', turbo: state.filter.turbo})
  },

  /** Fetch an item specified by ID */
  fetchItem ({commit, dispatch}, {id}) {
    return API.getItem(id)
      .then(response => {
        // Update customer
        // Customer may be undefined
        response.customer &&
          dispatch('customers/add', {items:[{id:response.customer}]}, {root:true})

        // Add an item
        commit('add', {items: [response]})

        return response
      })
  },

  /** Remove items */
  remove ({commit, dispatch}, {items}) {
    return Promise.all(items.map(item => (
      API.removeItem(item.id).then(() => 
        // Update customer force
        item.customer &&
          dispatch('customers/add', {
            items: [{id:item.customer}],
            // Turn force on
            options: {force: true}
          }, {root:true})
      )
    ))).then(() => {
      commit('remove', {items})
    })
  },

  /** Save an item */
  save ({state, getters, commit, dispatch}, {id}) {
    // Load item
    const item = _cloneDeep(getters['byId'](id))

    // Strip unnecessary properties
    delete item.publishedAt
    delete item.updatedAt
    if (item.id === 'new') delete item.id

    // Clear timeout
    state.timeoutId[item.id] && clearTimeout(state.timeoutId[item.id])

    return API.updateItem(item)
      .then(response => {
        // Update an item
        commit('add', {items: [response]})

        // Update customer force
        // Customer may be undefined
        response.customer &&
          dispatch('customers/add', {
            items: [{id:response.customer}],
            // Turn force on
            options: {force: true}
          }, {root:true})

        return response
      })
  },

  /**
   * Set items. If status of the added item isn't 4 it will be saved
   */
  async set ({state, getters, commit, dispatch}, {items}) {
    // Save items if status of the item isn't 4
    items.forEach(item => {
      if (item.id && item.id !== 'new' &&
            getters['byId'](item.id) &&
            getters['byId'](item.id).status !== 4) {
        // Clear timeout
        state.timeoutId[item.id] && clearTimeout(state.timeoutId[item.id])
        // Save with delay
        state.timeoutId[item.id] = setTimeout(() => dispatch('save', {id: item.id}), 3000)
      }
    })

    commit('add', {items: items})
  },
}

// mutations
const mutations = {
  /** Add items at the beginning of all */
  unshift (state, val) {
    state.all.unshift(...val.map(item => normalizeItem(item)))
  },

  /**
   * Add items to collections
   * If collection is not specified items are only added to the all item collection.
   * If you're adding an item to the collection that are already in the collection, they'll be merged
   */
  add (state, {items, collection, total}) {
    items.forEach(item => {
      // Merge items to “all”
      Vue.set(state.all, item.id, normalizeItem(_mergeWith(
        {},
        state.all[item.id],
        item,
        (a, b) => {
          // Don't merge arrays
          if (Array.isArray(a)) return b
        }
      )))
      // Add to the collection
      if (collection) {
        state.collections[collection].items.push(item.id)
        state.collections[collection].total = total
      }
    })
  },

  /** 
   * Reset the specified collection
   */
  resetCollection (state, {collection, items}) {
    collection && Vue.set(state.collections, collection, {items:items, total:items.length})
  },

  /** Remove items */
  remove (state, {items}) {
    items.forEach(item => {
      Vue.delete(state.all, item.id)
    })
  },

  /** Clean up data */
  clean (state) {
    // Remove unnecessary collections
    Object.keys(state.collections).forEach(item =>
      item !== 'mine' && Vue.delete(state.collections, item)
    )
    // Remove items except mine
    Object.keys(state.all).forEach(item =>
      state.collections.mine &&
        state.collections.mine.items.indexOf(item) === -1 &&
          Vue.delete(state.all, item)
    )
    // Remove timeout IDs
    Object.values(state.timeoutId).forEach(item => clearTimeout(item))
    Vue.set(state, 'timeOutId', {})
  },

  /** Update state */
  state (state, val) {
    Object.assign(state, val)
  },
}

export const create = () => ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    filter: createFilter()
  }
})

export default create()